/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {}


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {   
  #home-wrapper {
    background-image: url(https://media.complex.com/websites/seeyounextyear/bg-v2.jpg);
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
    .nav-text {
        font-size: 1.3em;
    }


    .nav-text {
        padding: 0 60px;
    }
    #links {
      max-width: 500px;
    }

    #title {
        img {
          max-width: 500px;
        }
      }


  .presents-image {

    a {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  #plane-outer-container {
    left: 0;
    height: auto;
    max-width: 300%;
  }

  #plane-container {
    left: -25vw;
  }

  
  #plane {
    height: 12vw;
    width: 50%;
    min-height: 150px;
    max-height: 200px;
    transform: translateX(-100%) scale(1);
    transition: transform 0.2s ease-in-out;    
  }

  #plane.static {
    &:hover {
      transform: translateX(-100%) scale(1.03);
    }
  }

    #home-wrapper {
      font-size: 15px;
    }

    #ot-sdk-btn {
      &.ot-sdk-show-settings {
        font-size: 10px;
      }
    }

    #footer {
      
      a {
        font-size: 10px;
      }

      #ot-sdk-btn.ot-sdk-show-settings {
        font-size: 10px;
      }
    }

    #footer-container {
      justify-content: flex-end;
    }

    #onetrust-banner-sdk {
      padding: 0 20px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {}
