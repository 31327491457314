.app {
  background-color: #252122;
}

#onetrust-consent-sdk {
  font-family: 'Galvji';
  font-weight: 400;
  font-style: normal;
}


#home-wrapper {
  height: 100vh;
  background-size: cover;
  background-position: center top;
  background-image: url(https://media.complex.com/websites/seeyounextyear/bg-v2-mobile.jpg);
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  font-family: 'Galvji';
  font-weight: 400;
  font-style: normal;
  
}

#graphics-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}


#links {
  position: absolute;
  width: 100%;
  min-width: 300px;
  height: 75%;
  top: 0;
  max-width: 70vw;
}

#title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2vh 5vw 0 5vw;
  position: relative;

  // adjusted to 103% from 100% to accommodate addition of TM
  img {
    width: 103%;
  }
}

.presents-image {
  width: 20vw;
  min-width: 150px;
  max-width: 250px;
  &#x {
    width: 5vw;
    min-width: 40px;
    max-width: 50px;
  }

}

#presents-top {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#presents-bottom {
  width: 10vw;
  min-width: 60px;
  max-width: 120px;
}

#plane-outer-container {
  width: 300%;
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
  max-width: 2000px;
}

#plane-container {
  width: 100%;
  height: 0;
  padding-bottom: 9.453125%;
  position: relative;
  display: flex;
  justify-content: center;
}

#load-plane {
  background-image: url('https://media.complex.com/websites/seeyounextyear/plane-sprites-ps-export.png');
  display: none;
}

#plane {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translateX(-100%);
    background-image: url('https://media.complex.com/websites/seeyounextyear/plane-sprites-ps-export.png');
    background-repeat: no-repeat;
    background-size: auto 1500%;
    background-position: 50% 0%;
}

#cta {
  width: clamp(200px, 20vw, 300px);
  position: absolute;
  bottom: -70%;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.2));
  }
}

#footer-container {
  display: flex;
  justify-content: center;
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
}

#footer {
  margin: 10px;
  color: #eeebe9;
  a {
    text-decoration: none;
    color: #eeebe9;
    margin: 0 10px;
    font-size: 8px;
    text-shadow: 2px 2px 8px #252122;

    &:hover {
      opacity: 0.5;
    }
  }

  #ot-sdk-btn {
    &.ot-sdk-show-settings {
      text-decoration: none;
    color: #eeebe9;
    margin: 0 10px;
    font-size: 8px;
    text-shadow: 2px 2px 8px #252122;


    &:hover {
      opacity: 0.5;
    }
      padding: 0;
      bottom: auto;
      right: auto;
      border: none;
      background: none;
      color: inherit;
      text-transform: uppercase;
  
      &:hover,
      &:focus {
        outline: none;
        cursor: pointer;
      }
    }
  }
}

#onetrust-banner-sdk {
  padding: 20px;
}

#onetrust-accept-btn-handler {
  margin: 0 auto !important;
}

