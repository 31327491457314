#nav {
    background-color: #252122;
    height: 5.6%; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    img {
        height: 80%;
    }
  }

.nav-text {
    font-family: orator-std, monospace;
    font-weight: lighter;
    font-size: 1em;
    font-style: normal;
    color: #eeebe9;
    transform: scaleY(1.3);
    margin-bottom: 0.5%;
    text-decoration: none !important;
    padding: 0 5vw;
}

  .spacer {
      visibility: hidden;
  }