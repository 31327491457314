@font-face {
    font-family: 'Galvji';
    src: url('../fonts/Galvji-BoldOblique.woff2') format('woff2'),
        url('../fonts/Galvji-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Galvji';
    src: url('../fonts/Galvji-Bold.woff2') format('woff2'),
        url('../fonts/Galvji-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Galvji';
    src: url('../fonts/Galvji.woff2') format('woff2'),
        url('../fonts/Galvji.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Galvji';
    src: url('../fonts/Galvji-Oblique.woff2') format('woff2'),
        url('../fonts/Galvji-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@import url("https://use.typekit.net/knb7wev.css");